/* Reset styles for all elements */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: black;
  color: white;
  overflow-y: auto
}

/* Apply text-shadow only to h1 elements */
h1 {
  text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 black;
}

.container {
  text-align: center;
}

.nav {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 5rem;
  padding: 0 1rem;
}

.site-title img {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  font-weight: bold;
}
